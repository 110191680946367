class Tag extends React.Component {

    constructor(props) {
        super(props);
        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        this.props.onChange(this.props.name, true)
    }

    render() {
        let cls = this.props.selected ? "tag btn btn-primary" : "tag btn btn-light"
        return <button type="button" className={cls} onClick={this.handleClick}>{this.props.name}</button>
    }
}

class CategorySelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tags: ["Magazin", "Ekonomi", "Yasam", "Spor"],
            selection: {} };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTagToggle = this.handleTagToggle.bind(this);
    }

    handleSubmit(event) {
        let selection = Object.keys(this.state.selection)
        alert('A name was submitted: ' + selection.toString());
        event.preventDefault();
    }

    handleTagToggle(name, value) {
        let currentTagState = this.state.selection[name] || false
        let s = this.state.selection
        this.setState(state => {
            s[name] = !currentTagState
            return {selection: s}
        })
    }

    render() {
        return (
            <form className='message' onSubmit={this.handleSubmit}>
                <p className="entry">
                    {this.props.message}
                </p>
                <div>
                    {this.state.tags.map(tag => (
                        <Tag name={tag} selected={this.state.selection[tag] || false} onChange={this.handleTagToggle}/>
                    ))}
                </div>
                <p>
                    <input type="submit" value="Submit" className="btn btn-dark" />
                </p>
            </form>
        );
    }
}

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = { currentMessage: "" };
    }

    componentDidMount() {
        axios.get("https://api.orkunozbek.com/categorize/message").then(function(response) {
            console.log(response)
            this.setState(state => ({
                currentMessage: {
                    id: response.data['id'],
                    message: response.data['message']
                }
            }));
        });
    }

    /*
    tick() {
      this.setState(state => ({
        currentMessage: "buz kovasıyla meydan okuma kostümü dolar"
      }));
    }
    */

    render() {
        let currentMessage = this.state.currentMessage
        return (<div>
            {currentMessage.length > 0 &&
            <CategorySelector message={currentMessage} />
            }
        </div>)
    }

}

ReactDOM.render(
    <App />,
    document.getElementById('root')
);